import { useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { getRSS } from '../../../../store/user-slice';
import { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import reGrab from '../../../../utils/reGrab';
import { userActions } from '../../../../store/user-slice';
import ReactHtmlParser from 'react-html-parser';
import './RssFeed.css';
import {v4 as uuid } from 'uuid'
const RssFeed = () => {
    const dispatch = useDispatch();
    const rssUrl = useSelector(state=>state.user.user.information.rssUrl)
    const scrollingSpeed = 15;
    const scrollingDelay = 5000;
    const scrollingHeight = useCallback(node => {
        if (node !== null) {
            setScrollingTime(node.getBoundingClientRect().height/scrollingSpeed);
        }
      }, []);
    const [scrollingTime,setScrollingTime] = useState(50);
    const [rssFeedIndex,setRssFeedIndex] = useState(0);
    const rssFeed = useSelector(state=>state.user.user.rssFeed)
    const getRSSFunction = useCallback(()=>{
        dispatch(getRSS());
    },[dispatch])
    const clearStatus = useCallback(()=>{
        dispatch(userActions.setOperations({
                function: 'getRSS',
                status: '',
        }));
    },[dispatch])

    //this is the timer that runs to refresh what we get back from the RSS call
    useEffect(() => {
        let timer;
        if(rssUrl!==""){
            reGrab(3000,getRSSFunction,'getRSS',clearStatus);
            timer = setInterval(() => {
                reGrab(3000,getRSSFunction,'getRSS',clearStatus);
            }, 3600000)
        }
        return () => {
            clearInterval(timer);
        }
    }, [getRSSFunction,clearStatus,rssUrl])

    const parseOptions = {
        decodeEntities : true,
        transform : (node) =>{
            if (node.type === 'tag' && node.name === 'placeholder') {
                return null;
              }
        }
    }

    const rssData = rssFeed.map(item=>{
        return <div key={uuid()} 
                    id="RSS" 
                    ref={scrollingHeight}
                    style={{
                        fontSize:14,
                        animation: `moveSlideshow ${scrollingTime}s linear ${scrollingDelay/1000}s 1 normal forwards, fadeInAnimation 1s linear 0s 1 normal forwards` ,
                    }}
                    className='FadeIn'
                    onClick={(e)=>e.preventDefault()}
                    onAnimationEnd={(e)=>{
                        console.log(e);
                        if(e.animationName==='fadeInAnimation')
                            return;
                        setRssFeedIndex((prevstate) => {
                            if (prevstate === rssFeed.length - 1) {
                                return 0;
                            }
                            else {
                                return prevstate + 1
                            }          
                        })    
                    }}
                       >
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <br/>
                           <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >     
                                <Grid
                                    container
                                    item
                                    xs={8}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                 >     
                                    <u>{item.title}</u> 
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={4}
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="flex-start"
                                  
                                    style={{fontSize:10}}
                                >
                                    {item.date.slice(0,14)}
                                </Grid>
                            </Grid>
                        
                        {ReactHtmlParser(item.content,parseOptions)}
                        </Grid>
                </div>
    })
   
    if(rssData.length === 0)
        return <div></div>
    
    return (
        <div style={{width:'100%',height:'100%'}}>
            {
                rssData[rssFeedIndex]
            }
            <div id="borderFade"></div>
        </div>
    )
}
export default RssFeed;

