import Grid from '@material-ui/core/Grid';
const WeatherToday = props => {
    const iconURL = `https://openweathermap.org/img/wn/${props.weatherToday.icon}@2x.png`
    let conditions;
    if (props.lang === "en")
        conditions = [
            'Current Conditions',
            'Feels like',
            'Wind',
            'Humidity',
            'Pressure',
     
        ]
    else if (props.lang === "el")
        conditions = [
            'Κατάσταση τώρα',
            'Αίσθηση',
            'Αέρας',
            'Υγρασία',
            'Πίεση',
        ]

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
        >   
            <Grid item xs={4} style={{textAlign: "center"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        {conditions[0]} {props.weatherToday.description}
                    </Grid>
                    <Grid item>
                        <img src={iconURL} alt="" width="50px" height="50px" style={{marginTop:"10px"}}/> 
                    </Grid>
                    <Grid item>
                    {Math.round(props.weatherToday.temp)}&deg;
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs item style={{textAlign: "center"}}>{conditions[1]} {Math.round(props.weatherToday.feels)}&deg; </Grid>
            <Grid xs item style={{textAlign: "center"}}>{conditions[2]} @ {props.weatherToday.wind} </Grid>
            <Grid xs item style={{textAlign: "center"}}>{conditions[3]} @ {props.weatherToday.humidity}% </Grid>
            <Grid xs item style={{textAlign: "center"}}>{conditions[4]} @ {props.weatherToday.pressure} hPa</Grid>
            <div/>
        </Grid>

    )
}
export default WeatherToday;