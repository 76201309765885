import { useDispatch, useSelector } from 'react-redux';
import { getCelebrationDays } from '../../../../store/user-slice';
import { useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import './CelebrationDays.css';
import reGrab from '../../../../utils/reGrab';
import { userActions } from '../../../../store/user-slice';
import { Grid } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

const CelebrationDays = (props) => {
    const dispatch = useDispatch();
    const celebrationDays = useSelector(state => state.user.user.celebrationDays)
    const CelebrationDaysOperation = useSelector(state => state.user.operations.getCelebrationDays)
    const language = useSelector(state=> state.user.user.information.language);

    const getCelebrationDaysFunction = useCallback(() => {
        dispatch(getCelebrationDays(language));
    }, [dispatch,language])
    const clearStatus = useCallback(()=>{
        dispatch(userActions.setOperations({
                function: 'getCelebrationDays',
                status: '',
        }));
    },[dispatch])
    useEffect(()=>{
        if(language!==undefined)
        reGrab(3000,getCelebrationDaysFunction,'getCelebrationDays',clearStatus);
    },[language,getCelebrationDaysFunction,clearStatus])
    useEffect(() => {
            reGrab(3000,getCelebrationDaysFunction,'getCelebrationDays',clearStatus);
            const currentTime = new Date().getTime();  //current unix timestamp     
            const execTime = new Date().setHours(0, 0, 15, 0);  //API call time = today at 00:00:01
            let leftTime;

            if(currentTime < execTime){
                 leftTime = execTime - currentTime;
            }else{
                 leftTime =   86400000 + execTime - currentTime
            }

            let timer;
            const timeout = setTimeout( function() {
                //Fetch data
                //getCelebrationDaysFunction();
                reGrab(3000,getCelebrationDaysFunction,'getCelebrationDays',clearStatus);
                timer = setInterval(function () {
                    // Fetch data
                    //getCelebrationDaysFunction();
                    reGrab(3000,getCelebrationDaysFunction,'getCelebrationDays',clearStatus);
                }, 86400000);
           }, leftTime)

        
        return () =>{
            clearInterval(timeout);
            clearInterval(timer);
        } 
        

    }, [getCelebrationDaysFunction,clearStatus]);

    
    if (CelebrationDaysOperation.status !== 'Failed' && celebrationDays.length === 0) {
        return <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            spacing={2}
            style={{paddingRight:'4px'}}
        ><div><CircularProgress></CircularProgress></div></Grid>
    }

    if (CelebrationDaysOperation.error !== undefined) {
        return <div>{CelebrationDaysOperation.error}</div>
    }
    let printValues = celebrationDays.map((celebrationDay) => {
        return (
            <Grid  item 
                container
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                key={uuid()}>
                        <Grid item style={{ fontWeight: 'bold' }}>
                            {celebrationDay.date}  
                        </Grid>
                    
                        <Grid item style={{ fontStyle: 'italic',wordWrap:'break-word' }}>
                            {celebrationDay.name} 
                        </Grid>
                        
            </Grid>
        )
    })

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            style={{paddingRight:'4px'}}
        >
            <Grid item style={{fontSize:'1.2rem'}}>
                {language === 'el' ? 'Εορτολόγιο' :  'Name Days'}
            </Grid>
            <Grid item 
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-end"
                spacing={2}>
                    {printValues}
            </Grid>
        </Grid>
    )
}

export default CelebrationDays;

/*
<div style={{width:'fit-content',position:'absolute',right:'0px'}}>
            <div>
                <div className="celebH1" style={{ fontWeight: 'bold' , fontSize: '1.2rem',position:'absolute',right:'0px',marginTop:'5px',textDecoration:'underline'}}>
                    
                </div>
            </div>
            
            
            <div style={{marginTop:'30px'}}>
            {printValues}
            </div>
            
        </div>
*/