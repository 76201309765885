import './Logo.css';
import Grid from '@material-ui/core/Grid';
import JoinLogo from '../../../../assets/JOIN.png'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useState} from 'react';
import { Box } from '@mui/system';
import { List,ListItem,ListItemText} from '@mui/material';
import PopupLogout from './popupLogout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import WebIcon from '@mui/icons-material/Web';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from "react-redux";
const Logo = (props) => {
    const [showMenu,setShowMenu] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const language = useSelector(state=> state.user.user.information.language)
    const w = window.innerHeight / 6;
    let justifyContent = "center";
    if (props.y === 0) {
        justifyContent = "flex-start";
    }
    if (props.y >= 4) {
        justifyContent = "flex-end";
    }
    let alignItems = 'center'
    if (props.x === 0)
        alignItems = "flex-start";
    if (props.x >= 2)
        alignItems = 'flex-end'

    const toggleDrawer = (open) => (event) => {
        if (event &&event.type === 'keydown' &&(event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setShowMenu(open);
    }


    const browserHandler = () =>{
        
        const event = new Event('open-browser');
        document.dispatchEvent(event);
    }
    const kodiHandler = () =>{
        const event = new Event('open-kodi');
        document.dispatchEvent(event);
    }

    const logoutHandler = () =>{

        setOpenPopup(true);
    }

    const menuList = <Box
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                        
                    >
                        <List style={{fontSize:'5rem'}}>                                            
                            <ListItem>
                                <ListItemButton onClick={browserHandler}>
                                    <ListItemIcon> 
                                        <WebIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={language === 'el' ? 'Πρόγραμμα περιήγησης': 'Browser'} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={kodiHandler}>
                                    <ListItemIcon> 
                                        <LiveTvIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={language === 'el' ? 'Τηλεόραση': 'Television'} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={logoutHandler}>
                                    <ListItemIcon> 
                                        <LogoutIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={language === 'el' ? 'Αποσύνδεση': 'Logout'} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
    return (
        <Grid
            container
            direction="column"
            justifyContent={justifyContent}
            alignItems={alignItems}
            className="Logo___container" 
            id="web_btn">
            <img src={JoinLogo} width={w} height={w} alt="Logo" onClick={toggleDrawer(true)} referrerPolicy="no-referrer"/>
            <SwipeableDrawer
                anchor={'right'}
                open={showMenu}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}     
            >
                 {menuList}
            </SwipeableDrawer>
            {openPopup && <PopupLogout openLogoutPopup={openPopup} setOpenPopup={setOpenPopup} /> }
        </Grid>)
}

export default Logo;