import { useState} from 'react';
import { authLogin } from '../../../store/authorization-slice';
import { useDispatch,useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import { Box } from '@mui/system';



const useStyles = makeStyles((theme)=>({
    root: {
        maxWidth:450,
        paddingLeft:50,
        paddingRight:50,
        padding: 20,
      
    },
    form: {
        '& > *': {
          margin: theme.spacing(1),
          width: '25ch',
        },
      },
      error:{
        maxWidth:350,
        color:"red",
    },
    errorCard:{
        padding:20,
        border: "1px solid red",
        backgroundColor: "transparent",
    },
  }));


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
    backgroundColor: "black",
    textAlign: "center",
    borderRadius: "10px"
  };


const LogIn = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [password,setPassword] = useState('');
    const [email,setEmail] = useState('');
    const loginOperation = useSelector(state => state.authorization.operations.login);
    const [language,setLanguage]= useState('en')
    const loginHandler = async (e) =>{
        e.preventDefault();
        dispatch(authLogin(email,password,language));     
    }
    const emailChangeHandler = (e) =>{
        setEmail(e.target.value)
    }
    const passwordChangeHandler = (e) =>{
        setPassword(e.target.value)
    }
    const swapLanguage = () =>{
            if(language==='el'){
                setLanguage('en')
                return;
            }
            setLanguage('el');
    }

    const backWifiList = () =>{
        const event = new Event('load-version-wifi');
        document.dispatchEvent(event);
    }

    return (
  
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                {language === 'el' ? 'Είσοδος Smart Mirror' : 'Login Smart Mirror'} 
                </Typography>
                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={2}>
                    
                    <Grid item style={{width: "100%"}}>
                        <TextField fullWidth variant="outlined" color="primary" label={language==='el'?  'Διεύθυνση ηλεκτρονικού ταχυδρομείου':"Email"} value={email}  onChange={emailChangeHandler}></TextField><br />
                    </Grid>
                    <Grid item style={{width: "100%"}}>
                        <TextField fullWidth variant="outlined" color="primary" type="password" label={language === 'el' ? 'Κωδικός' : 'Password'} value={password} onChange={passwordChangeHandler}> </TextField><br />
                    </Grid>
                    <Grid item style={{width: "100%", marginTop: "15px"}}>
                        <Button variant="outlined" style={{border: "2px solid white", color: "white", width: "100%", height: "80px", backgroundColor: "#303030"}} type="submit" onClick={loginHandler}>
                            {language === 'el' ? 'Εισοδος' : 'Login'} 
                        </Button>
                    </Grid>
                    <Grid item style={{width: "100%", marginTop: "15px",}}>
                        <Button variant="outlined" style={{border: "2px solid white", color: "white", width: "100%", height: "80px", backgroundColor: "#303030"}} type="submit" onClick={backWifiList}>
                            {language === 'el' ? 'Πiσω στην επιλογh Wifi' : 'Back to Wifi'} 
                        </Button>
                    </Grid>
                    <GTranslateIcon style={{position: "absolute", right: 10, top: 10}} onClick={swapLanguage}/>
                
            {loginOperation.error &&
                <Paper elevation={3} className={classes.errorCard}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >

                        <Grid item>
                            <Typography  component="div" noWrap={false} paragraph={true} className={classes.error} variant="body1">
                                <ErrorOutlineOutlinedIcon />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="div" noWrap={false} paragraph={true} className={classes.error} variant="body1">
                                {loginOperation.error}
                            </Typography>
                        </Grid>

                    </Grid>
                </Paper>
            }


            </Grid>
                </Box>

    );
};

export default LogIn;