import './Logo.css';
import { useState} from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { logout, authorizationActions } from '../../../../store/authorization-slice';
import { useDispatch,useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import validator from 'validator';
import { Paper } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const useStyles = makeStyles((theme)=>({
    root: {
        maxWidth:450,
        paddingLeft:50,
        paddingRight:50,
        padding: 20,
      
    },
    form: {
        '& > *': {
          margin: theme.spacing(1),
          width: '25ch',
        },
      },
    error:{
        maxWidth:350,
        color:"red",
       
        
    },
    errorCard:{
        padding:20,
        border: "1px solid red",
        backgroundColor: "transparent",
    },
    textFieldPopup:{
        backgroundColor: "transparent",
        border: "1px solid white",
    }

  }));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid white',
  boxShadow: 24,
  p: 4,
  backgroundColor: "black",
  textAlign: "center",
  borderRadius: "10px"
};


const PopupLogout = (props) => {
    const classes = useStyles();
    const language = useSelector( state => state.user.user.information.language);
    const logoutOperation = useSelector(state => state.authorization.operations.logout);
    const [password,setPassword] = useState('');
    const [email,setEmail] = useState('');
    const dispatch = useDispatch();

    const handleClose = () =>{
       props.setOpenPopup(false);
       dispatch(authorizationActions.setOperations({
        function: 'logout',
        status: '',
        error: ''
        }))
    }

    const emailChangeHandler = (e) =>{
        setEmail(e.target.value)
    }

    const passwordChangeHandler = (e) =>{
        setPassword(e.target.value)
    }

    const logoutHandler = (e) =>{
        console.log("Inside logout Handler");
        if (!validator.isEmail(email.trim())) {
            dispatch(authorizationActions.setOperations({
                function: 'logout',
                status: '',
                error: language === 'el' ? 'Λάθος Διεύθυνση ηλεκτρονικού ταχυδρομείου' : 'Email is not valid'
            }))
            return;
        }
        if (!validator.isLength(password.trim(), { min: 5 })) {
            dispatch(authorizationActions.setOperations({
                function: 'logout',
                status: '',
                error: language === 'el' ? 'Ο κωδικός πρέπει να είναι τουλάχιστον 5 χαρακτήρες' : 'Password needs to be at least 5 characters long.'
            }))
            return;
        }
        dispatch(logout(email.trim(), password.trim(), language));   
    }

    return (
            <Modal
                open={props.openLogoutPopup}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {language === 'el' ? 'Αποσύνδεση' : 'Logout'}
                </Typography>
                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={2}>
                    
                    <Grid item style={{width: "100%"}}>
                        <TextField fullWidth variant="outlined" color="primary" label={language==='el'?  'Διεύθυνση ηλεκτρονικού ταχυδρομείου':"Email"} value={email}  onChange={emailChangeHandler}></TextField><br />
                    </Grid>
                    <Grid item style={{width: "100%"}}>
                        <TextField fullWidth variant="outlined" color="primary" type="password" label={language === 'el' ? 'Κωδικός' : 'Password'} value={password} onChange={passwordChangeHandler}> </TextField><br />
                    </Grid>
                    <Grid item style={{width: "100%", marginTop: "15px"}}>
                        <Button variant="outlined" style={{border: "2px solid white", color: "white", width: "100%", height: "80px", backgroundColor: "#303030"}} type="submit" onClick={logoutHandler}>
                            {language === 'el' ? 'Αποσυνδεση' : 'Logout'} 
                        </Button>
                    </Grid>
                
                
            {logoutOperation.error &&
                <Paper elevation={3} className={classes.errorCard}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >

                        <Grid item>
                            <Typography  component="div" noWrap={false} paragraph={true} className={classes.error} variant="body1">
                                <ErrorOutlineOutlinedIcon />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="div" noWrap={false} paragraph={true} className={classes.error} variant="body1">
                                {logoutOperation.error}
                            </Typography>
                        </Grid>

                    </Grid>
                </Paper>
            }


            </Grid>
                </Box>
            </Modal>
    )
}

export default PopupLogout;

