import authorizationReducer from './authorization-slice';
import userReducer from './user-slice';

import { configureStore } from '@reduxjs/toolkit'

const store = configureStore({
    reducer: { 
        authorization: authorizationReducer ,
        user: userReducer, 
    }
});


export default store;