import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store/store';
import { Provider } from 'react-redux';
import './index.css'
import './assets/fonts/MPLUS/MPLUSRounded1c-Regular.ttf'
import './assets/fonts/MPLUS/MPLUSRounded1c-Bold.ttf'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary:{
      main:'#ffffff'
    },
    secondary: {
      main: '#ffffff'
    }
  },
  components: {
    MuiListItemIcon:{
      styleOverrides:{
        root:{color:'white'},
        fontSize:'4rem'
      }
    },
    MuiInputAdornment:{
      styleOverrides:{
        root:{color:'white'},
        outlined:{color:'white'},
        positionEnd:{color:'white'}
      }
    },
    MuiInputLabel:{
      styleOverrides:{
        root:{
          borderColor:'white',
          color: 'white',
        },
        outlined:{
          borderColor:'white',
          color: 'white',
        }
      }
    },
    MuiTextField:{
      styleOverrides:{
        root:{
          borderColor:'white',
          color: 'white',
        },
        formControl:{
          borderColor:'white',
          color: 'white',
        },
        inputAdornedEnd:{
          color: 'white',
        },
        cssLabel: {
          color : 'white'
        },
        cssOutlinedInput: {
          '&$cssFocused $notchedOutline': {
            color: 'white',
            borderColor: `white !important`,
          }
        },
        notchedOutline: {
          borderWidth: '1px',
          color: 'white',
          borderColor: 'white !important'
        },
      }
    },
    MuiOutlinedInput:{
      styleOverrides:{
        root:{
          borderColor:'white',
          color: 'white',
        },
        formControl:{
          borderColor:'white',
          color: 'white',
        },
        inputAdornedEnd:{
          color: 'white',
        },
        cssLabel: {
          color : 'white'
        },
        cssOutlinedInput: {
          '&$cssFocused $notchedOutline': {
            color: 'white',
            borderColor: `white !important`,
          }
        },
        notchedOutline: {
          borderWidth: '1px',
          color: 'white',
          borderColor: 'white !important'
        },
      }
    },
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
