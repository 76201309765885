import { createSlice } from '@reduxjs/toolkit';
import graphqlFetch from '../utils/fetch-graphql.js';
//User initial State 
const initialUserState = { 
    user:{
        sockets : false,
        information:{
            language: undefined,
            rssUrl: '', 
        },
        detailed: false,
        news:[],
        rssFeed:[],
        celebrationDays: [],
    },
    operations: {
        getWeather:{
            status:'',
            error:undefined
        },
        getNews:{
            status:'',
            error:undefined
        },
        getCelebrationDays:{
            status:'',
            error:undefined
        },
        fetch:{
            status:'',
            error:undefined
        },
        getRSS:{
            status:'',
            error:undefined
        }
        // googleGetMails:{
        //     status:'',
        //     error:undefined
        // }
    }
}

//user slice initialization 
const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser(state,action){
            state.user.information.language = action.payload.information.language
            state.user.information.rssUrl = action.payload.information.rssUrl
            state.user.detailed = true;
        },
        setLanguage(state,action){
            state.user.information.language = action.payload;
        },
        setSockets(state,action){
            state.user.sockets = action.payload;
        },
        setOperations(state,action){
            state.operations[action.payload.function] = {
                status : action.payload.status || '',
                error : action.payload.error || undefined
            };
        },
        setNews(state,action){
            state.user.news = action.payload
        },
        setRSS(state,action){
            state.user.rssFeed = action.payload
        },
        // setMails(state,action){
        //     state.user.mails = action.payload
        // },
        setWeather(state,action){
            
            state.user.weather= action.payload
        },
        setCelebrationDays(state,action){
            state.user.celebrationDays = action.payload
        },
        updateUser(state,action){
            if(action.payload?.information?.rssUrl==='')
                state.user.rssFeed= [];
            state.user = {
                ...state.user,
                ...action.payload
            }
        },
        // updateMails(state,action){
        //     if(action.payload.operation === 'addedMessage'){
        //         if(state.user.mails.find(mail => mail.id === action.payload.mail.id))
        //             return;
        //         if(state.user.mails.length >= 7)
        //             state.user.mails.pop();
        //         state.user.mails.unshift(action.payload.mail)

        //     }
        //     if(action.payload.operation === 'trashMessage'){

        //         const message = state.user.mails.find(mail => mail.id === action.payload.id);
        //         if(message)
        //         message.labels = ['TRASH']
        //     }
        //     if(action.payload.operation === 'readMessage'){
        //         const message = state.user.mails.find(mail => mail.id === action.payload.id);
        //         if(message)
        //         message.labels = message.labels.filter(label => label !== 'UNREAD')
        //     }
        // },
        resetUser(state, action){
            console.log("Reset User");
            console.log(initialUserState);
            state.user = initialUserState.user;
            state.operations = initialUserState.operations;
        }
    }
});
export const userActions = userSlice.actions;

// export const googleGetMails = () => {
//     return (dispatch) => {
//         dispatch(userActions.setOperations({status:'Pending',function:'googleGetMails'}))
//         const graphqlQuery = {
//             query: ` 
//                 query googleGetMails{
//                     googleGetMails{
//                         subject
//                         from
//                         date
//                         id
//                         labels
//                     }
//                 }
//             `
//         }
//         graphqlFetch(graphqlQuery)
//             .then(result=>{
//                 if(result?.errors?.length > 0){
//                     dispatch(userActions.setOperations({
//                         status:'Failed',
//                         function:'googleGetMails',
//                         error:result.errors[0].message
//                     }))
//                     return;
//                 }
//                 dispatch(userActions.setOperations({
//                     status:'Success',
//                     function:'googleGetMails'
//                 }))
//                 dispatch(userActions.setMails(result.data.googleGetMails))
//             }).catch(err=>{
//                 dispatch(userActions.setOperations({
//                     status:'Failed',
//                     function:'googleGetMails',
//                     error:'Something went wrong.'
//                 }))
//             })
//     };
// }
export const fetchUser = () => {
    return (dispatch) => {
        dispatch(userActions.setOperations({status:'Pending',function:'fetch'}))
        const graphqlQuery = {
            query: ` 
                query getUser{
                    getUser{
                        information{
                            language
                            rssUrl
                        }
                    }
                }
            `
        }
        graphqlFetch(graphqlQuery)
            .then(result=>{
                if(result?.errors?.length > 0){
                    dispatch(userActions.setOperations({
                        status:'Failed',
                        function:'fetch',
                        error:result.errors[0].message
                    }))
                    return;
                }
                dispatch(userActions.setOperations({
                    status:'Success',
                    function:'fetch'
                }))
                dispatch(userActions.setUser(result.data.getUser))
            }).catch(err=>{
                dispatch(userActions.setOperations({
                    status:'Failed',
                    function:'fetch',
                    error:'Something went wrong.'
                }))
            })
    };
    
}

export const getNews = () => {
    return (dispatch)=>{
        dispatch(userActions.setOperations({
            function: 'getNews',
            status: 'Pending'
        }))
        const graphqlQuery= {
            query: 
                `
                    query getNews{
                        getNews{
                            title
                            pubDate
                        }
                    }
            
            `
        }
        graphqlFetch(graphqlQuery)
            .then((res)=>{
                if(res?.errors?.length > 0){
                    dispatch(userActions.setOperations({
                        function: 'getNews',
                        status: 'Failed',
                        error: res.errors[0].message
                    }))
                    return;
                }
                dispatch(userActions.setNews(res.data.getNews));
                dispatch(userActions.setOperations({
                    function: 'getNews',
                    status: 'Success',
                }))
            }).catch(err=>{
                dispatch(userActions.setOperations({
                    function: 'getNews',
                    status: 'Failed',
                    error: 'Something went wrong.'
                }))
            })
    };
}
export const getRSS = () => {
    return (dispatch)=>{
        dispatch(userActions.setOperations({
            function: 'getRSS',
            status: 'Pending'
        }))
        const graphqlQuery= {
            query: 
                `
                    query getRSS{
                        getRSS{
                            title 
                            content
                            date
                        }
                    }
            
            `
        }
        graphqlFetch(graphqlQuery)
            .then((res)=>{
                if(res?.errors?.length > 0){
                    dispatch(userActions.setOperations({
                        function: 'getRSS',
                        status: 'Failed',
                        error: res.errors[0].message
                    }))
                    return;
                }
                dispatch(userActions.setRSS(res.data.getRSS));
                dispatch(userActions.setOperations({
                    function: 'getRSS',
                    status: 'Success',
                }))
            }).catch(err=>{
                dispatch(userActions.setOperations({
                    function: 'getRSS',
                    status: 'Failed',
                    error: 'Something went wrong.'
                }))
            })
    };
}
export const getWeather = () => {
    return (dispatch)=>{
        dispatch(userActions.setOperations({
            function: 'getWeather',
            status: 'Pending'
        }))
        const graphqlQuery= {
            query: 
                `
                {
                    getWeather{
                      WeatherToday{
                        temp 
                        humidity
                        cloudIndex
                        description
                        feels
                        wind
                        pressure
                        icon
                      }
                      WeekWeather{
                        icon
                      }
                      isDefault
                    }
                  }
            
            `
        }
        graphqlFetch(graphqlQuery)
            .then((res)=>{
                if(res?.errors?.length > 0){
                    dispatch(userActions.setOperations({
                        function: 'getWeather',
                        status: 'Failed',
                        error: res.errors[0].message
                    }))
                    return;
                }
                dispatch(userActions.setWeather(res.data.getWeather));
                dispatch(userActions.setOperations({
                    function: 'getWeather',
                    status: 'Success',
                }))
            }).catch(err=>{
                dispatch(userActions.setOperations({
                    function: 'getWeather',
                    status: 'Failed',
                    error: 'Something went wrong.'
                }))
            })
    };
}
export const getCelebrationDays = () => {
    
    return (dispatch)=>{
        dispatch(userActions.setOperations({
            function: 'getCelebrationDays',
            status: 'Pending'
        }))
        const graphqlQuery= {
            query: 
                `
                query getCelebrationDates{
                    getCelebrationDates{
                      date
                      name
                    }
                }
            
            `
        }
        graphqlFetch(graphqlQuery)
            .then((res)=>{
                if(res?.errors?.length > 0){
                    dispatch(userActions.setOperations({
                        function: 'getCelebrationDays',
                        status: 'Failed',
                        error: res.errors[0].message
                    }))
                    return;
                }
                dispatch(userActions.setCelebrationDays(res.data.getCelebrationDates));
                dispatch(userActions.setOperations({
                    function: 'getCelebrationDays',
                    status: 'Success',
                }))
            }).catch(err=>{
                dispatch(userActions.setOperations({
                    function: 'getCelebrationDays',
                    status: 'Failed',
                    error: 'Something went wrong.'
                }))
            })
     };
}

export default userSlice.reducer;