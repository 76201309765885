import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
const textArray = [
    [
        "Hello there",
        "How is it going?",
    ],
    [
        "Γεια σας",
        "Πως είστε σήμερα",
    ],
]


const Greetings = () => {
    const [indexToShow,setIndexToShow]=useState(0);
    const language = useSelector(state=>state.user.user.information.language);
    let languageIndex=0;
    if(language==='el')
        languageIndex=1
    useEffect(()=>{
        const interval = setInterval(()=>{
            setIndexToShow(prev=>{
                if(prev+1 === textArray[languageIndex].length)
                    return 0
                return prev+1;
            })
        },30000)
        return () => {
            clearInterval(interval);
        }
    })
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            style={{width:'100%',height:'100%'}}
            >
            <Grid item style={{fontSize:'1.8rem'}}>
                    
                        {textArray[languageIndex][indexToShow]}
                    
            </Grid>
        </Grid>
       
    )
}
export default Greetings;