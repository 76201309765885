import { createSlice } from '@reduxjs/toolkit';
import graphqlFetch from '../utils/fetch-graphql.js';
import { userActions } from './user-slice.js';


//Initialization Data
const initialAuthorizationState = { 
    authorizationStatus: localStorage.getItem('token') ? true : false,
    operations:{
        login: {
            status:'',
            error:undefined,
        },
        logout: {
            status:'',
            error:undefined,
        },
        // googleSignIn:{
        //     status:'',
        //     error:undefined,
        // }
    }
}

//Slice Initialization
const authorizationSlice = createSlice({
    name: 'authorization',
    initialState: initialAuthorizationState,
    reducers: {
        //set Authorization Status 
        setAuthorizationStatus(state,action) {
            state.authorizationStatus = action.payload;
        },
        setOperations(state,action){
            const data = action.payload;
            state.operations[data.function] = {
                status : data.status || 'Missing Status',
                error : data.error || undefined
            }
        }

    }
});
export const authorizationActions = authorizationSlice.actions;

export const authLogin = (email,password,language) => {
    return (dispatch) => {
        dispatch(authorizationActions.setOperations({
            function: 'login',
            status: 'Pending'
        }))
        const graphqlQuery= {
            query: 
                `
                    query loginUser($userInput: loginInput!){
                        loginUser(userInput: $userInput){
                            token
                            user{
                                name
                                email
                                createdWith
                                information{
                                    coords{
                                        lat
                                        lon
                                    }
                                    rssUrl
                                }
                            }
                        }
                    }
            
            `,variables:{
                userInput:{
                    password,
                    email,
                    language
                }
            }
        }
        graphqlFetch(graphqlQuery)
            .then((res)=>{
                if(res?.errors?.length > 0){
                    dispatch(authorizationActions.setOperations({
                        function: 'login',
                        status: 'Failed',
                        error: res.errors[0].message
                    }))
                    return;
                }
                localStorage.setItem('token',res.data.loginUser.token);
                dispatch(authorizationActions.setAuthorizationStatus(true));
                dispatch(authorizationActions.setOperations({
                    function: 'login',
                    status: 'Success',
                }))
            }).catch(err=>{
                dispatch(authorizationActions.setOperations({
                    function: 'login',
                    status: 'Failed',
                    error: language==='el'? 'Κάτι πήγε στραβά, παρακαλώ περιμένετε':'Something went wrong.'
                }))
            })
    };
}



export const logout = (email,password,language) => {
    return (dispatch) => {
        dispatch(authorizationActions.setOperations({
            function: 'logout',
            status: 'Pending'
        }))
        const graphqlQuery= {
            query: 
                `
                    query loginUser($userInput: loginInput!){
                        loginUser(userInput: $userInput){
                            token
                        }
                    }
            
            `,variables:{
                userInput:{
                    password,
                    email,
                    language
                }
            }
        }
        graphqlFetch(graphqlQuery)
            .then((res)=>{
                if(res?.errors?.length > 0){
                    dispatch(authorizationActions.setOperations({
                        function: 'logout',
                        status: 'Failed',
                        error: res.errors[0].message
                    }))
                    return;
                }
                localStorage.removeItem('token');
                dispatch(authorizationActions.setAuthorizationStatus(false));
                dispatch(userActions.resetUser());
                
                dispatch(authorizationActions.setOperations({
                    function: 'logout',
                    status: 'Success',
                }))
            }).catch(err=>{
                dispatch(authorizationActions.setOperations({
                    function: 'logout',
                    status: 'Failed',
                    error: language==='el'? 'Κάτι πήγε στραβά, παρακαλώ περιμένετε':'Something went wrong.'
                }))
            })
    };
}




// export const GoogleSignIn  = (googleData) =>{
//     return ( dispatch ) => {
       
//         dispatch(authorizationActions.setOperations({
//             function: 'googleSignIn',
//             status: 'Pending'
//         }))
//         const graphqlQuery= {
//             query: 
//                 `
//                     mutation googleSignIn($userInput: String!){
//                         googleSignIn(userInput: $userInput)
//                     }
            
//             `,variables:{
//                 userInput: googleData
//             }
//         }
//         graphqlFetch(graphqlQuery)
//             .then((res)=>{
//                 if(res?.errors?.length > 0){
//                     dispatch(authorizationActions.setOperations({
//                         function: 'googleSignIn',
//                         status: 'Failed',
//                         error: res.errors[0].message
//                     }))
//                     return;
//                 }
//                 dispatch(authorizationActions.setOperations({
//                     function: 'googleSignIn',
//                     status: 'Success',
//                 }))
//             }).catch(err=>{
//                 dispatch(authorizationActions.setOperations({
//                     function: 'googleSignIn',
//                     status: 'Failed',
//                     error: 'Something went wrong.'
//                 }))
//             })
//     }
// }
export default authorizationSlice.reducer;