import { useSelector } from "react-redux";

import Unauthorized from "./components/unauthorized/unauthorized";
import Authorized from "./components/authorized/authorized";
//export this history so redux files can use it to push to different pages after function ends
import { useEffect } from "react";
import sockets from "./utils/sockets";
const App = () => {
  const authorizationStatus = useSelector(state => state.authorization.authorizationStatus);
  useEffect(()=>{
    if(authorizationStatus===true)
      sockets();
  },[authorizationStatus])

  
  return (
    <div className="App">
        {authorizationStatus ? <Authorized/> : <Unauthorized/>}
    </div>
  );
}

export default App;

//MAJOR BUG // EMAILS WONT SEND