import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import './DateAndTime.css';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
const DateAndTime = props => {
    const moduleOptions = useSelector(state=> state.user.user.information.language)
    const language = moduleOptions;
    let weekday;
    let months;
    if (language === 'el') {
        weekday = [
            'Κυριακή',
            'Δευτέρα',
            'Τρίτη',
            'Τετάρτη',
            'Πέμπτη',
            'Παρασκευή',
            'Σάββατο'
        ]
        months = [
            'Ιανουάριος',
            'Φεβρουάριος',
            'Μάρτιος',
            'Απρίλιος ',
            'Μάιος',
            'Ιούνιος',
            'Ιούλιος',
            'Αύγουστος',
            'Σεπτέμβριος',
            'Οκτώβριος',
            'Νοέμβριος',
            'Δεκέμβριος',
        ]
    } else {
        weekday = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
        ]
        months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
    }
    const [date, setDate] = useState(new Date());
    const [displayTime, setDisplayTime] = useState();
    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
            const intervalDate = new Date();
            let intervalHours;
            let intervalMinutes;
            let intervalSeconds;
            if (intervalDate.getHours() < 10)
                intervalHours = `0${intervalDate.getHours()}`
            else
                intervalHours = `${intervalDate.getHours()}`
            if (intervalDate.getMinutes() < 10)
                intervalMinutes = `0${intervalDate.getMinutes()}`
            else
                intervalMinutes = `${intervalDate.getMinutes()}`
            if (intervalDate.getSeconds() < 10)
                intervalSeconds = `0${intervalDate.getSeconds()}`
            else
                intervalSeconds = `${intervalDate.getSeconds()}`
            setDisplayTime({
                hours: intervalHours,
                minutes: intervalMinutes,
                seconds: intervalSeconds
            })
        }, 1 * 1000); // every 1 seconds
        return () => {
            clearInterval(timer);
        }
    }, [])
    
    let justifyContent = 'flex-start';
    let alignItems = 'flex-start';
    let direction = "column";
    if (props.y === 0) {
        justifyContent = 'flex-start';
    }
    else if (props.y >= 4) {
        justifyContent = 'flex-start';
        direction = "column-reverse"
    } else {
        justifyContent = 'center';
    }
    if (props.x === 0)
        alignItems = 'flex-start';
    else if (props.x === 2) {
        alignItems = 'flex-end';
    }
    else
        alignItems = 'center';
    if (displayTime === undefined)
        return <Grid container alignItems={alignItems} direction={direction} justifyContent={justifyContent} className="DateAndTime___container">
            <CircularProgress/>
            
            </Grid>
    return (
        <Grid container alignItems={alignItems} direction={direction} justifyContent={justifyContent} className="DateAndTime___container">
            <Grid item className="DateAndTime___Date">
                <Box>
                    {`${weekday[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`}
                </Box>
            </Grid>
            <Grid item>
                    <Box className="DateAndTime___HoursMinutes">
                        {`${displayTime.hours}:${displayTime.minutes}`}
                </Box>
                <Box  className=" DateAndTime___Seconds">
                        {`${displayTime.seconds}`}
                </Box>
            </Grid>
        </Grid>
        )
}
export default DateAndTime;