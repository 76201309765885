import { Responsive, WidthProvider } from 'react-grid-layout';
import {v4 as uuid} from 'uuid';
import React from 'react';
import Greetings from './modules/Greetings/Greetings';
import './authorized.css'
import Blank from './modules/blanks/blank';
import DateAndTime from './modules/DateAndTime/DateAndTime';
import Logo from './modules/Media/Logo';
import News from './modules/News/News';
import Weather from './modules/Weather/Weather';
import CelebrationDays from './modules/CelebrationDays/CelebrationDays';
import { useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { fetchUser } from '../../store/user-slice';
//import sockets from '../../utils/sockets';
import CircularProgress from '@material-ui/core/CircularProgress';
import { authorizationActions } from '../../store/authorization-slice';
import RssFeed from './modules/RssFeed/RssFeed';


// import GoogleGraphical from './modules/GoogleGraphical/Google';
const layoutData = [
    {
        i: uuid(),
        y:0,
        x:0,
        w:1,
        h:2,
        module:'DateAndTime'
    },
    {
        i: uuid(),
        y:0,
        x:1,
        w:1,
        h:2,
        module:'Blank'
    },
    {
        i: uuid(),
        y:0,
        x:2,
        w:1,
        h:2,
        module:'Logo'
    },
    {
        i: uuid(),
        y:2,
        x:0,
        w:1,
        h:2,
        module:'RssFeed'
    },
    {
        i: uuid(),
        y:2,
        x:1,
        w:1,
        h:2,
        module:'Greetings'
    },
    {
        i: uuid(),
        y:2,
        x:2,
        w:1,
        h:2,
        module:'CelebrationDays'
    },
    {
        i: uuid(),//celeb days if server is offline keep trying
        y:4,
        x:0,
        w:3,
        h:1,
        module:'News'
    },
    {
        i: uuid(),
        y:5,
        x:2,
        w:3,
        h:1,
        module:'Weather'
    },
];

const Components = {
    Blank,
    DateAndTime,
    Logo,
    News,
    Weather,
    Greetings,
    CelebrationDays,
    RssFeed
}


let layoutItems = layoutData.map((item) => {
    //this line creates a component dynamically 
    let ModuleInserted = React.createElement(Components[item.module],item);
    let options = { i: uuid(), y: item.y, w: item.w, h: item.h, x: item.x, isDraggable: false, isResizable: false }
    return <div className="layoutMapper___container___item" key={uuid()} data-grid={options} style={{overflow:'hidden'}} >  {ModuleInserted} </div>
});
const Authorized = () => {
    const dispatch= useDispatch();
    const language = useSelector(state=> state.user.user.information.language)
    const fetchOperationStatus = useSelector(state=>state.user.operations.fetch)
    let token = localStorage.getItem('token')
    useEffect(()=>{
        if(fetchOperationStatus.error === 'Unauthorized Attempt' || fetchOperationStatus.error === 'Unauthorized entry attempt denied' || fetchOperationStatus.error === 'No user found'){
            dispatch(authorizationActions.setAuthorizationStatus(false))
            localStorage.removeItem('token');
        }
    },[fetchOperationStatus,dispatch,token])

    
    useEffect(()=>{
        if (language === undefined) {
            dispatch(fetchUser())
        }
    },[dispatch,language])
    useEffect(()=>{
        const interval = setInterval(()=>{
            if(fetchOperationStatus.status==='Failed')
                dispatch(fetchUser())
            if(fetchOperationStatus.status==='Success'){
                clearInterval(interval)
            }
        },3000)
        return ()=>{
            clearInterval(interval)
        }
    },[fetchOperationStatus.status,dispatch])
    const ResponsiveGridLayout = WidthProvider(Responsive);

    if(language ===undefined){
        return <div ><CircularProgress/></div>
    }
 

    return (
        
        <div className="layoutMapper___container" >
            <ResponsiveGridLayout
                    breakpoints={{  xxs: 3 }}
                    cols={{  xxs: 3}}
                    rowHeight={window.innerHeight / 6}
                    compactType='vertical'
                    margin={[0, 0]}
                    >
                    {layoutItems}
            </ResponsiveGridLayout >
        </div>
    )
}
export default Authorized;

