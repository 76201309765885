import { useEffect, useState } from 'react'
import './News.css';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useDispatch,useSelector } from 'react-redux';
import { getNews } from '../../../../store/user-slice';
import { useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import reGrab from '../../../../utils/reGrab';
import { userActions } from '../../../../store/user-slice';
const News = (props) => {
    const dispatch = useDispatch();
    const [newsIndex, setNewsIndex] = useState(0);
    const news = useSelector(state=>state.user.user.news)
    const getNewsOperation = useSelector(state=>state.user.operations.getNews)
    const language = useSelector(state=>state.user.user.information.language);
    const getNewsFunction = useCallback(()=>{
        dispatch(getNews());
    },[dispatch])
    const clearStatus = useCallback(()=>{
        dispatch(userActions.setOperations({
                function: 'getNews',
                status: '',
        }));
    },[dispatch])

    //this is the timer that runs to refresh what we get back from the RSS call
    useEffect(() => {
        reGrab(3000,getNewsFunction,'getNews',clearStatus);
        const timer = setInterval(() => {
            reGrab(3000,getNewsFunction,'getNews',clearStatus);
        }, 3600000)
        return () => {
            clearInterval(timer);
        }
    }, [getNewsFunction,clearStatus])
    useEffect(()=>{
        if(language!==undefined){
            reGrab(3000,getNewsFunction,'getNews',clearStatus);
        }
    },[language,getNewsFunction,clearStatus])
    useEffect(() => {
          //timer to run and interval 
        if (news.length === 0) {
            return;
        }
        const interval = setInterval(() => {
            setNewsIndex((prevstate) => {
                if (prevstate === news.length - 1) {
                    return 0;
                }
                else {
                    return prevstate + 1
                }          
            })      
        }, 15000)
        return () => {
            clearInterval(interval);
        }
    }, [news])
    if (news.length === 0 && getNewsOperation.status!=='Failed')
        return <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
      ><div><CircularProgress /></div></Grid>
    if (getNewsOperation.error!==undefined){
        return <div>{getNewsOperation.error}</div>
    }
    let date = news[newsIndex].pubDate.substring(17, 22);
    let direction= "";
    let align = "";
    if (props.y >= 4) {
        align = "flex-end"
        direction="column"
    }
    else if (props.y === 0) {
        align = "flex-start"
        direction = "column-reverse"
    }
    else {
        align = "center"
        direction = "column"
    }
    if(props.y>-1)
    return (
        <Box display="flex" alignItems={align} className="news__container">
            <Grid
                  container
                  direction={direction}
                  justifyContent="center"
                  alignItems="center"
                > 
                <Grid item className="text-center news__container___date" style={{textAlign: "center"}}>
                    {language === 'el' ? 'Νέα της Ελλάδας' : 'News'}, {date}
                </Grid>
                <Grid item className="text-center news__container___title" style={{textAlign: "center"}}> 
                    {news[newsIndex].title}
                </Grid>
             </Grid>
        </Box>
        )
    
    
}
export default News;