import Grid from '@material-ui/core/Grid';
import {v4 as uuid } from 'uuid'
const WeatherWeek = props => {

    let weekday;
    if (props.lang === "en")
    weekday = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
        ]
    else if (props.lang === "el")
        weekday = [
            'Κυρ',
            'Δευ',
            'Τρι',
            'Τετ',
            'Πεμ',
            'Παρ',
            'Σαβ'
        ]
    const weatherWeek = props.weatherWeek.map((day, index) => {
        const today = new Date();
        
        const nextDays = new Date(today);
        nextDays.setDate(nextDays.getDate() + index)
        const dayIndex = nextDays.getDay();
        const formatedDate = nextDays.getDate();
        const formatedMonth = nextDays.getMonth() + 1;
        return {
            iconURL: `https://openweathermap.org/img/wn/${day.icon}@2x.png`,
            date: `${formatedDate}/${formatedMonth}`,
            formatedDay : weekday[dayIndex]

        }
    })
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
        > 
            {weatherWeek.map((day) => 
                <Grid item key={uuid()} style={{textAlign: "center",display:"flex"}} >
                     <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            {day.formatedDay}    
                        </Grid>
                        <Grid item>
                            <img src={day.iconURL} alt="" width="50px" height="50px" style={{marginTop:"10px"}}/>  
                        </Grid>
                        <Grid item>
                            {day.date} 
                        </Grid>
                    </Grid>
                </Grid> 
            )}   
        </Grid>

    )
}
export default WeatherWeek;