import Grid from '@material-ui/core/Grid';
import LogIn from "./login/login";

const Unauthorized = () => {
    return (<>
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="GridContainer"
        >
                    <LogIn/>

        </Grid>
    </>)
}
export default Unauthorized;

