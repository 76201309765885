import './Weather.css'
import reGrab from '../../../../utils/reGrab';
import { useEffect } from 'react';
import WeatherToday from './WeatherToday';
import WeatherWeek from './WeatherWeek';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { getWeather } from '../../../../store/user-slice';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { userActions } from '../../../../store/user-slice';

const Weather = (props) => {
    const getWeatherOperation = useSelector(state=>state.user.operations.getWeather)
    const weatherData = useSelector(state=>state.user.user.weather)
    const dispatch = useDispatch();
    
    const getWeatherFunction = useCallback(()=>{
        dispatch(getWeather())
    },[dispatch])
    const clearStatus = useCallback(()=>{
        dispatch(userActions.setOperations({
                function: 'getWeather',
                status: '',
        }));
    },[dispatch])
    const language = useSelector(state=> state.user.user.information.language);
  
    useEffect(()=>{
        if(language!==undefined)
        reGrab(3000,getWeatherFunction,'getWeather',clearStatus);
    },[language,getWeatherFunction,clearStatus])

    useEffect(() => {
        reGrab(3000,getWeatherFunction,'getWeather',clearStatus);
        const currentTime = new Date().getTime();  //current unix timestamp                                      
        const execTime = new Date().setHours(0, 0, 15, 0);  //API call time = today at 00:00:01
        let timeLeft;
        if (currentTime < execTime) {
            //it's currently earlier than 00:00:01
            timeLeft = execTime - currentTime;
        } else {
            //it's currently later than 00:00:01, schedule for tomorrow at 00:00:01
            timeLeft = execTime + 86400000 - currentTime
        }
        let timer;                                          
        const timeout = setTimeout(function () {
            reGrab(3000,getWeatherFunction,'getWeather',clearStatus);
            timer = setInterval(function () {
                reGrab(3000,getWeatherFunction,'getWeather',clearStatus);
            }, 86400000);  //repeat every 24h 86400000
        }, timeLeft);  //wait until 00:00:01 as calculated above

        //clear timers if somehow recalled
        return () => {
            clearInterval(timer);
            clearTimeout(timeout);
        }
    }, [getWeatherFunction,clearStatus])
    if (!weatherData && getWeatherOperation.status!=='Failed')
        return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      ><div><CircularProgress/></div></Grid>
    if (getWeatherOperation.error!==undefined )
        return <div>{getWeatherOperation.error}</div>
    return (
       
        <Grid display="flex" className={`weather___container`}>       
                <div>
                    <WeatherToday lang={language} weatherToday={weatherData.WeatherToday} />
                    <WeatherWeek lang={language} weatherWeek={weatherData.WeekWeather}/>
                    {weatherData.isDefault !== null && 
                        (language==='en' ? 
                        'This is the default value, please update your coordinates through our cms' 
                        : 
                        'Παρακαλώ κάντε ανανέωση των συντεταγμένων')
                    }
                </div>
        </Grid>
    )
}

export default Weather;