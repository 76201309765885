import store from '../store/store'

const reGrab =  async (intervalTimer,getData,operation,clearStatus) => {
    const interval = setInterval(async()=>{
        const operationState = store.getState().user.operations[operation];
        if(operationState.status==='Success'){
            clearStatus();
            clearInterval(interval);
            return;
        }else if(operationState.status!=='Pending' && operationState.status!=='Success'){
            getData();
        }
    },intervalTimer)
}
export default reGrab;