import store from '../store/store';
import { userActions } from '../store/user-slice';
// import { googleGetMails } from '../store/user-slice';
const sockets = () => {
    console.log('sockets called again');
    let token = localStorage.getItem('token')
    if (token !== null) {
        var ws = new WebSocket(`wss://joinsmartmirror.com/WSS/path?${token}`);
        ws.onopen = function () {
            console.log('wss connected');
        };

        ws.onmessage = function (e) {
            if (e.data === 'ping') {
                ws.send('pong');
                if(!localStorage.getItem('token'))
                    ws.close();
            }
            else {
                const updateData = JSON.parse(e.data);
                store.dispatch(userActions.updateUser(updateData));
            }
        };

        ws.onclose = function (e) {
           
            if(localStorage.getItem('token')){
                console.log('recalling sockets function with timeout')
                setTimeout(function () {
                    sockets();
                }, 5000);
            }
        };

        ws.onerror = function (err) {
            ws.close();
        };
    }

}

export default sockets;